'use client'

import { useSession } from 'auth/clerk'
import { useGlobalErrorReporting } from 'appsignal/client'

export const GlobalErrorProvider = () => {
  const session = useSession()

  const userId = session.session?.user.id || null

  useGlobalErrorReporting(userId)

  return null
}
